<template>
 <div class="bg-white shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
  <div class="max-w-7xl mx-auto 2xl:px-0 px-4">
   <form
    @submit.prevent="postCTI($t('elementCreatedSuccessfully', { element: $t('CTI') }))"
    method="POST"
   >
    <div class="space-y-10">
     <div class="pt-5 border-b border-gray-200 pb-12">
      <div class="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
       <div class="sm:col-span-3">
        <label for="cti-name" class="capitalize block text-sm font-medium leading-6 text-gray-900">
         {{ $t("name") }}
        </label>
        <div class="mt-2">
         <input
          v-model="this.form.name"
          required=""
          type="text"
          name="cti-name"
          id="cti-name"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
         />
        </div>
       </div>
      </div>
     </div>
    </div>

    <div class="mt-6 pb-12 flex items-center justify-end gap-x-6">
     <button
      type="button"
      @click="this.$emit('closeForm')"
      class="rounded-md bg-white px-3 py-2 border border-gray-300 shadow-sm text-sm font-semibold text-gray-900 hover:bg-gray-50"
     >
      {{ $t("ticket.cancel") }}
     </button>
     <button
      type="submit"
      class="rounded-md body__table--header px-3 py-2 text-sm font-semibold text-white shadow-sm"
     >
      {{ $t("ticket.send") }}
     </button>
    </div>
   </form>
  </div>
 </div>
</template>

<script>
import axios from "axios";
import ConfirmationDialog from "../components/ConfirmationDialog.vue";

const account = localStorage.getItem("account");

export default {
 components: {
  ConfirmationDialog,
 },
 data() {
  return {
   account,
   form: {
    name: "",
   },
  };
 },
 methods: {
  async postCTI(msg) {
   const options = {
    method: "POST",
    url: `${this.$cookie.getCookie("API")}/api/v1/ctis`,
    params: {
     customerAccount: `${this.account}`,
     name: `${this.form.name}`,
    },
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };

   axios
    .request(options)
    .then((res) => {
     this.form.name = "";
     this.closeForm = !this.closeForm;
     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", msg);
     this.$router.push(`/cti-detail/${this.account}/${res.data.id}`);
    })
    .catch(function (err) {
     console.error(err);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", this.errorDataResponse);
    });
  },
  sendMessage(msg, subMsg) {
   this.showMessage = true;
   this.messageToShow = msg;
   this.subMessageToShow = subMsg;
  },
 },
};
</script>

<style></style>
