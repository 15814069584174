<template>
 <PageHeading
  :title="$t('CTIConfiguration')"
  :button-title="$t('newCti')"
  :has-action-button="true"
  :has-search="true"
  :has-meta-data="false"
  :last-button-title="
   userPermissions.permissions.includes('smart-routings.*') ||
   userPermissions.permissions.includes('smart-routings.delete')
    ? $t('deleteAll')
    : undefined
  "
  :has-permission="
   userPermissions.permissions.includes('smart-routings.*') ||
   userPermissions.permissions.includes('smart-routings.create')
  "
  @open="(this.openForm = !this.openForm), (this.openUpdateForm = false)"
  @import="isPopoverOpen = !isPopoverOpen"
  @export="exportContacts()"
  @delete="
   deleteAll(
    $t('elementDeletedSuccessfully', { element: $t('CTI') }),
    $t('deleteAllCTI'),
    $t('deleteAllCTIDetail'),
    $t('delete'),
    $t('cancel')
   )
  "
  @update:search="$emit('update:search', $event)"
 />
 <div v-if="openForm || openUpdateForm" class="flex flex-col">
  <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
   <div class="py-5 align-middle inline-block min-w-full sm:px-6 lg:px-10">
    <CTIForm
     v-if="this.openForm"
     @closeForm="this.openForm = false"
     @activeBanner="$emit('activeBanner'), getCTIs()"
     @successMsg="$emit('successMsg', $event)"
     @success="$emit('success', $event)"
    />
    <UpdateCTIForm
     v-if="this.openUpdateForm"
     :name="this.ctiName"
     :id="this.ctiId"
     @closeForm="this.openUpdateForm = false"
     @activeBanner="$emit('activeBanner'), getCTIs(), (openUpdateForm = false)"
     @successMsg="$emit('successMsg', $event)"
     @success="$emit('success', $event)"
    />
   </div>
  </div>
 </div>

 <div>
  <div class="flex flex-col">
   <div class="-my-0 sm:-mx-6 lg:-mx-0">
    <div
     v-if="tbody?.length > 0 && !this.preview"
     class="align-middle inline-block min-w-full sm:px-6 lg:px-10"
    >
     <CTITableComponent
      :thead="thead"
      :tbody="filteredData()"
      :preview="preview"
      :data="[]"
      @copyClipboard="copyClipboard($event)"
      @deleteCti="(...args) => deleteCti(...args)"
      @openEditForm="(...args) => this.openEditForm(...args)"
      @sort="(this.currentSort = $event), sort($event)"
      @action="getData($event)"
      @action-detail="goToDetail($event)"
     />
    </div>
    <div v-else-if="this.preview" class="align-middle inline-block min-w-full sm:px-6 lg:px-10">
     <CTITableComponent
      :thead="thead"
      :tbody="previewData.elements"
      :data="[]"
      :preview="preview"
      :previewData="previewData"
      @action="getData($event)"
      @sort="(this.currentSort = $event), sort($event)"
      @activeBanner="$emit('activeBanner')"
      @successMsg="$emit('successMsg', $event)"
      @success="$emit('success', $event)"
     />
    </div>
    <div v-else class="justify-center pt-20">
     <EmptyState
      :title="$t('emptyStateTitle', { title: $t('destination') })"
      :subtitle="$t('emptyStateSubtitle', { title: $t('destination') })"
     />
    </div>
   </div>
  </div>
 </div>

 <AskConfirmationDialog ref="askConfirmationDialog" />
</template>

<script>
import axios from "axios";
import PageHeading from "../components/PageHeading.vue";
import AskConfirmationDialog from "../components/AskConfirmationDialog.vue";
import CTITableComponent from "../components/CTITableComponent.vue";

import CTIHeader from "../components/CTIHeader.vue";
import SearchFilter from "../components/SearchFilter.vue";
import TablePagination from "../components/TablePagination.vue";
import CTIForm from "../components/CTIForm.vue";
import UpdateCTIForm from "../components/UpdateCTIForm.vue";
import EmptyState from "../components/EmptyState.vue";

import { PlusIcon } from "@heroicons/vue/solid";

const account = localStorage.getItem("account");
const userPermissions = JSON.parse(localStorage.getItem("userPermissions"));

export default {
 props: ["search"],
 components: {
  AskConfirmationDialog,
  PageHeading,
  CTITableComponent,
  PlusIcon,
  CTIHeader,
  SearchFilter,
  TablePagination,
  CTIForm,
  UpdateCTIForm,
  EmptyState,
 },
 data() {
  return {
   CTIData: [],
   account,
   isLoading: true,
   fullPage: false,
   preview: false,
   previewData: [],
   currentSort: "id",
   currentSortDir: "desc",
   thead: [
    { name: "name", sort: "name", isSort: false },
    { name: "token" /*, sort: "lastName"*/, isSort: false },
   ],
   tbody: [],
   previewTableHeader: ["NAME", "TOKEN"],
   openForm: false,
   openUpdateForm: false,
   ctiName: "",
   ctiId: "",
   successMsg: "",
   activeBanner: false,
   isSuccess: true,
   key: false,
   userPermissions,
  };
 },
 methods: {
  openEditForm(...args) {
   this.openUpdateForm = true;
   this.ctiName = args[0];
   this.ctiId = args[1];
   this.openForm = false;
   window.scrollTo(0, 100);
  },
  setPreviewData(event) {
   this.previewData = event;
   this.preview = true;
  },
  async getCTIs() {
   this.$emit("setLoading", true);
   const res = await axios.get(
    `${this.$cookie.getCookie("API")}/api/v1/ctis?customerAccount=${this.account}`,
    {
     headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    }
   );
   if (res) {
    this.CTIData = res.data;
    this.tbody = res.data;
    this.$emit("setLoading", false);
   }
   this.$emit("setLoading", false);
  },
  sort(s) {
   if (s === this.currentSort) {
    this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
   }
   this.currentSort = s;
  },
  sortedData() {
   return this.tbody.sort((a, b) => {
    let toBeSorted = this.currentSort;
    let modifier = 1;
    if (this.currentSortDir === "desc") modifier = -1;

    if (a[toBeSorted] < b[toBeSorted]) return -1 * modifier;
    if (a[toBeSorted] > b[toBeSorted]) return 1 * modifier;

    return 0;
   });
  },
  filteredData() {
   return this.sortedData().filter((res) => {
    if (this.search) {
     const searchLower = this.search.toLowerCase();
     const nameMatch = res.name.toLowerCase().includes(searchLower);
     const tokenMatch = res.token.toLowerCase().includes(searchLower);

     return nameMatch || tokenMatch;
    }
    return res;
   });
  },
  async deleteAll(successMsg, title, confirmationMessage, confirmButton, goBack) {
   const r = await this.$refs.askConfirmationDialog.show({
    title: title,
    message: confirmationMessage,
    okButton: confirmButton,
    cancelButton: goBack,
    action: "delete",
   });
   if (r) {
    axios
     .delete(`${this.$cookie.getCookie("API")}/api/v1/ctis/all?customerAccount=${this.account}`, {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     })
     .then((res) => {
      if (res) {
       this.$emit("activeBanner");
       this.$emit("success", true);
       this.$emit("successMsg", successMsg);
       this.getCTIs();
      }
     })
     .catch((err) => {
      this.errorHandling(err);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
   }
  },
  async deleteCti(...args) {
   let id = args[0];
   let name = args[1];
   let title = this.$t("deleteCTI", { name: name });
   let confirmationMessage = this.$t("deleteCTIMessage");
   let confirmButton = this.$t("delete");
   let goBack = this.$t("cancel");
   let successMsg = this.$t("elementDeletedSuccessfully", { element: this.$t("CTI") });

   const r = await this.$refs.askConfirmationDialog.show({
    title: title,
    message: confirmationMessage,
    okButton: confirmButton,
    cancelButton: goBack,
    action: "delete",
   });
   if (r) {
    axios
     .delete(`${this.$cookie.getCookie("API")}/api/v1/ctis/${id}?customerAccount=${this.account}`, {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     })
     .then((res) => {
      if (res) {
       this.$emit("activeBanner");
       this.$emit("success", true);
       this.$emit("successMsg", successMsg);
       this.getCTIs();
      }
     })
     .catch((error) => {
      this.errorHandling(error);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
   }
  },
  copyClipboard(token) {
   try {
    navigator.clipboard.writeText(token);

    let msg = this.$t("contentCopied");
    this.$emit("activeBanner");
    this.$emit("success", true);
    this.$emit("successMsg", msg);
   } catch (err) {
    console.error("Failed to copy: ", err);
   }
  },
 },
 mounted() {
  this.getCTIs();
 },
};
</script>

<style></style>
