<template>
  <div class="z-10 top-0" :key="this.closeForm">
    <div :class="`flex flex-wrap justify-between items-center body__header cti_table_header border-t border-b`">
      <div class="">
        <button
          :class="[
            `rounded group body__header--button text-white sm:text-sm lg:text-sm xl:text-sm md:text-sm inline-flex items-center text-sm font-medium shadow p-2 mr-0`,
          ]"
          @click="this.$emit('openForm')"
        >
          <span class="uppercase">{{ this.buttonTitle }}</span>
        </button>
      </div>
      <div class="">
        <div class="">
          <button
            @click.prevent="
              deleteAll(
                'Delete all CTIs',
                'Do you wish to continue?',
                'Continue',
                'Go back',
                'CTIs deleted successfully'
              )
            "
            type="button"
            class="uppercase rounded inline-flex items-center p-2 border border-transparent text-sm font-medium shadow-sm text-white bg-red-500 hover:bg-red-700 focus:outline-none"
          >
            {{ $t("deleteAll") }}
          </button>
        </div>
      </div>
    </div>

    <!-- CTI form -->
  </div>
  <ask-confirmation-dialog
    ref="askConfirmationDialog"
  ></ask-confirmation-dialog>
  <ConfirmationDialog
    v-if="this.showMessage"
    :messageToShow="this.messageToShow"
    :subMessageToShow="this.subMessageToShow"
  />
</template>

<script>
import axios from "axios";

import { ChevronDownIcon } from "@heroicons/vue/solid";
import { mapGetters } from "vuex";

import ConfirmationDialog from "../components/ConfirmationDialog.vue";
import AskConfirmationDialog from "../components/AskConfirmationDialog.vue";

const account = localStorage.getItem("account");

export default {
  name: "CTIHeader",
  props: ["buttonTitle"],
  data() {
    return {
      isLoading: false,
      account,
      closeForm: true,
      showMessage: false,
      messageToShow: "",
      confirmationMessage: "",
      subMessageToShow: "",
    };
  },
  components: {
    ChevronDownIcon,
    ConfirmationDialog,
    AskConfirmationDialog,
  },
  methods: {
    async deleteAll(
      title,
      confirmationMessage,
      confirmButton,
      goBack,
      successMsg
    ) {
      const r = await this.$refs.askConfirmationDialog.show({
        title: title,
        message: confirmationMessage,
        okButton: confirmButton,
        cancelButton: goBack,
        action: "delete",
      });
      if (r) {
        axios
          .delete(
            `${this.$cookie.getCookie("API")}/api/v1/ctis/all?customerAccount=${
              this.account
            }`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            if (res) {
              // this.sendMessage(successMsg);
              this.$emit("activeBanner");
              this.$emit("success", true);
              this.$emit("successMsg", successMsg);
            }
          })
          .catch((err) => {
            this.errorHandling(err);
            this.$emit("activeBanner");
            this.$emit("success", false);
            this.$emit("successMsg", this.errorDataResponse);
          });
      }
    },
    sendMessage(msg, subMsg) {
      this.showMessage = true;
      this.messageToShow = msg;
      this.subMessageToShow = subMsg;
    },
  },
};
</script>

<style>
.cti_table_header {
  padding: 5px 40px 5px 40px;
}
</style>
